
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    import {str} from '@lit/localize';

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's84ae32ba760890f9': str`Oy, I'm the app shell!`,
'h4e032a9ba1fe5753': html`I'm reading my config from: <code>${0}</code>`,
's6ed675fff1cc77ed': str`Things I'm providing to the components subtree:`,
'sf48d916fa3ea8dd7': str`Locale:`,
'seac9afef1a00b5e8': str`waiting`,
'h4ecc1b3d116a58d7': html`Waiting for my <code>${0}</code> attribute to be defined`,
'hab2fdbba66c93e40': html`fetching config from <code>${0}</code>`,
's037df8d2f49aa980': str`loaded`,
's9f7452dd75d54d31': str`error`,
's032e92c80863cc5a': str`async going on`,
's9e9b04c54923ae13': str`idle`,
'sfa124b47e896b2aa': str`Waiting for pending state to be defined`,
    };
  